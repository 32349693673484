/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { DatapointDto } from './DatapointDto';
import type { SpecialDate } from './SpecialDate';

export type RuleResultDatapointDto = (DatapointDto & {
    anomalyImportance?: number | null;
    specialDates?: Array<SpecialDate>;
    status?: RuleResultDatapointDto.status;
} & {
    specialDates: Array<SpecialDate>;
    status: RuleResultDatapointDto.status;
});

export namespace RuleResultDatapointDto {

    export enum status {
        NOT_CHECKED = 'NOT_CHECKED',
        EXPECTED = 'EXPECTED',
        ANOMALY = 'ANOMALY',
    }


}
